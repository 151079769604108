exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-aanbod-gedroogde-pasta-js": () => import("./../../../src/pages/aanbod/gedroogdePasta.js" /* webpackChunkName: "component---src-pages-aanbod-gedroogde-pasta-js" */),
  "component---src-pages-aanbod-gevulde-pasta-js": () => import("./../../../src/pages/aanbod/gevuldePasta.js" /* webpackChunkName: "component---src-pages-aanbod-gevulde-pasta-js" */),
  "component---src-pages-aanbod-js": () => import("./../../../src/pages/aanbod.js" /* webpackChunkName: "component---src-pages-aanbod-js" */),
  "component---src-pages-aanbod-verse-pasta-js": () => import("./../../../src/pages/aanbod/versePasta.js" /* webpackChunkName: "component---src-pages-aanbod-verse-pasta-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacyPolicy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-recepten-js": () => import("./../../../src/pages/recepten.js" /* webpackChunkName: "component---src-pages-recepten-js" */)
}

